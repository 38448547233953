html {
  scroll-behavior: smooth;
}

body, p {
  position: relative;
	font-family: Georgia, Serif;
  font-weight: 400;
	font-size: 16px;
	line-height: 28px;
  color: #323232;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
  max-width: 1440px;
  background-color: white;
}

h1 {
  font-family: Helvetica, sans-serif;
  font-size: 36px;
  line-height: 38px;
}

h2 {
  font-family: Helvetica, sans-serif;
  font-weight: italic;
  font-size: 22px;
  line-height: 28px;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}

h3 {
  font-family: Georgia, Serif;
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  color: #555;
}



@media screen and (max-width: 767px) {
  html {
    text-rendering: optimizeLegibility;
    overflow: scroll;
    overflow-x: hidden;
  }
  html::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }
  h1 {
    font-size: 28px;
    line-height: 34px;
  }
}

a {
	color: #0096B8;
	text-decoration: underline;
}
a:hover, a:active {
  text-decoration: none;
  cursor: pointer;
}
em {
	font-style: italic;
}
b {
  font-family: "imperial-normal-700";
  font-weight: 700;
  font-style: bold;

}
::-moz-selection {
	background: #8EDEEF;
}
::selection {
	background: #8EDEEF;
}
/*override flexbox-grid to get full bleed*/
.row {
  margin-left: 0;
  margin-right: 0;
}

